<template>
<!--   <div>
    <div class="div_center_com big-zindex" style="height: 510px;position: relative;overflow: hidden">
      <div class="center"
           style="top: 160px;z-index:100;left:50%;transform:translateX(-50%);font-size: 90px;color: white;text-align: center;font-weight: bold;position: absolute">
        知识产权托管与服务系统
        <div style="     width: 830px;
            height: 84px;
            border-radius: 41px;
            background-color: rgb(254, 174, 30);
font-size: 50px;
margin-left: 50%;
transform: translateX(-50%)">
          您身边的知产财富贴心管家
        </div>
      </div>
      <div class="div_center_com small-zindex" style="height: 510px;position: absolute"></div>

    </div>
    <div style="height: 800px;position: relative" class="center circle_jx">
      <div style="width: 326px;height: 183px;margin: 0 auto;display: flex;align-items: center;justify-content: center">
        <span class="big-circle"></span><span style="font-size: 30px;font-weight: bold">托管系统的</span><span
        style="color: #6a66ff;font-size: 30px;font-weight: bold">核心功能</span><span class="small-circle"></span>
      </div>
      <div style="height: 540px;" class="center">
        <div
          style="margin: 0 auto;height: 540px;width: 540px;border: 2px dotted #6a66ff;border-radius: 50%;display: flex;align-items: center;justify-content: center;position: relative">
          <div
            style="height: 100px;width: 380px;position: absolute;left: 440px;bottom: 25px;display: flex;align-items: center">
            <img :src="ztjk" alt="">
            <div style="margin-left: 20px">
              <div style="text-align: left"><span style="font-size: 18px;color: black">状态监控</span></div>
              <div style="text-align: left">
                <span style="font-size: 14px;">专利审查与维持过程中60多个法律状态的实时监控，随时了解业务进程；</span>
              </div>
            </div>
          </div>
          <div
            style="height: 100px;width: 380px;position: absolute;right: 440px;bottom: 25px;display: flex;align-items:center;flex-direction: row-reverse">
            <img :src="zcfw" alt="">
            <div style="margin-right: 20px">
              <div style="text-align: right"><span style="font-size: 18px;color: black">知产服务</span></div>
              <div style="text-align: right">
                <span style="font-size: 14px;">提供专利、商标、版权、法律、资质、评估分析等一站式知识产权延伸服务；</span>
              </div>
            </div>
          </div>
          <div
            style="height: 100px;width: 380px;position: absolute;right: 440px;top: 25px;display: flex;align-items:center;flex-direction: row-reverse">
            <img :src="yjtz" alt="">
            <div style="margin-right: 20px">
              <div style="text-align: right"><span style="font-size: 18px;color: black">预警通知</span></div>
              <div style="text-align: right">
                <span style="font-size: 14px;">可选择微信、短信、邮件主动通知费用、状态信息，避免传统模式的遗忘；</span>
              </div>
            </div>
          </div>
          <div style="height: 100px;width: 380px;position: absolute;left: 500px;display: flex;align-items: center">
            <img :src="nfjk" alt="">
            <div style="margin-left: 20px">
              <div style="text-align: left"><span style="font-size: 18px;color: black">年费监控</span></div>
              <div style="text-align: left">
                <span style="font-size: 14px;">自动同步国知局专利年费信息，清晰显示费用明细，维持成本明明白白</span>
              </div>
            </div>
          </div>
          <div
            style="height: 100px;width: 380px;position: absolute;right: 500px;display: flex;align-items:center;flex-direction: row-reverse">
            <img :src="zxjf" alt="">
            <div style="margin-right: 20px">
              <div style="text-align: right"><span style="font-size: 18px;color: black">在线缴费</span></div>
              <div style="text-align: right">
                <span style="font-size: 14px;">在线支付，提供官方票据【自2021年1月1
日起专利缴费提供国知局电子票据；</span>
              </div>
            </div>
          </div>
          <div style="height: 100px;width: 380px;position: absolute;left: 440px;top: 25px;display: flex;align-items: center">
            <img :src="zljs" alt="">
            <div style="margin-left: 20px">
              <div style="text-align: left"><span style="font-size: 18px;color: black">专利检索</span></div>
              <div style="text-align: left">
                <span style="font-size: 14px;">支持全国2400万全量专利数据的检索、浏览、以及PDF文档的下载等；</span>
              </div>
            </div>
          </div>
          <div>
            <span style="font-size: 30px;color: white;font-weight: bold;padding-left: 15px">核心功能</span><br>
            <span style="font-size: 16px;color: #c0c5ff;word-spacing: 20px;">CORE FUNCTION</span>
          </div>
        </div>
      </div>

    </div>
    <div class="div_center_com cpjs">
      <div class="center" style="height: 100%">
        <div
          style="width: 326px;height: 183px;margin: 0 auto;display: flex;align-items: center;justify-content: center">
          <span class="big-circle"></span><span style="color: #6a66ff;font-size: 30px;font-weight: bold">产品</span><span
          style="font-size: 30px;font-weight: bold">介绍</span><span class="small-circle"></span>
        </div>
        <div class="p" style="width: 1020px;height: 300px;background-color: white;margin: 0 auto;overflow: hidden">
          <div style="width: 720px;height: 220px;background-color: #ECECF4;margin-left: 280px;margin-top: 40px">
            <div
              style="padding: 35px;padding-left: 90px;padding-bottom:30px;font-size: 18px;color: black;font-weight: bold">
              托管系统介绍
            </div>
            <div style="padding-left: 90px;font-size: 14px;color: black;line-height: 25px">
              专利托管系统可为企业、高校、政府、园区、专家、机构提供集 <br>
              <span style="color: #6a66ff">①检索查询—②专利监控—③预警通知—④在线缴费—⑥知产服务</span> <br>
              为一体的信息化工具与载体，助力创新主体的知识产权管理提升效率，<br>
              节省成本、降低风险、了解竞争对手、跟踪技术趋势。
            </div>
          </div>
        </div>
      </div>


    </div>

    <div class="div_center_com" style="height: 575px">
      <div style="width: 326px;height: 183px;margin: 0 auto;display: flex;align-items: center;justify-content: center">
        <span class="big-circle"></span><span
        style="color: #6a66ff;font-size: 30px;font-weight: bold">托管系统特色</span><span
        style="font-size: 30px;font-weight: bold">优势</span><span class="small-circle"></span>
      </div>
      <div class="center" style="height: 300px;display: flex;justify-content: space-between">
        <div
          style="height: 290px;width: 380px;box-shadow: 0 6px 20px -6px rgba(0,0,0,.1);padding-left: 40px;padding-top: 60px">
          <div style="margin-bottom: 30px"></div>
          <img :src="hj" alt="" style="transform: translateY(-20px)">
          <div style="font-size: 18px;color: black;font-weight: bold;margin-bottom: 30px">提升效率</div>
          <div style="font-size: 14px">在线缴费5分钟，线下折腾5小时</div>
        </div>
        <div
          style="height: 290px;width: 380px;box-shadow: 0 6px 20px -6px rgba(0,0,0,.1);padding-left: 40px;padding-top: 60px">
          <div style="margin-bottom: 30px"></div>
          <img :src="qb" alt="" style="transform: translateY(-20px)">
          <div style="font-size: 18px;color: black;font-weight: bold;margin-bottom: 30px">节省成本</div>
          <div style="font-size: 14px">传统机构50-100/件，线上代缴仅需5分之一</div>
        </div>
        <div
          style="height: 290px;width: 380px;box-shadow: 0 6px 20px -6px rgba(0,0,0,.1);padding-left: 40px;padding-top: 60px">
          <div style="margin-bottom: 30px"></div>
          <img :src="lq" alt="" style="transform: translateY(-20px)">
          <div style="font-size: 18px;color: black;font-weight: bold;margin-bottom: 30px;">降低风险</div>
          <div style="font-size: 14px">滞纳金、未缴费失效，知产损失从说拜拜</div>
        </div>
      </div>
    </div>
    <div class="div_center_com cpjs">

      <div class="center" style="height: 100%;overflow: hidden;background-color: white;position: relative">
        <div style="height: 80px;width: 80px;border-radius: 50%;background-color: #ffc331;margin-left: -30px;
    margin-top: -30px;"></div>
        <div style="height: 80px;width: 80px;border-radius: 50%;background-color: #8f96fe;    margin-left: 15px;
    margin-top: -10px"></div>
        <div
          style="height: 80px;width: 80px;border-radius: 50%;background-color: #8f96fe;float: right;margin-top: 435px"></div>
        <div style="width: 326px;height: 183px;position: absolute;top:60px;left: 52%;transform: translateX(-50%)">
          <span class="big-circle"></span><span style="color: #6a66ff;font-size: 30px;font-weight: bold">成功</span><span
          style="font-size: 30px;font-weight: bold">案例</span><span class="small-circle"></span>
        </div>

        <img style="height: 250px;width: 250px;border-radius: 50%;position: absolute;left: 120px;top: 160px"
             :src="cgcircle"/>

        <div style="font-size: 14px;line-height: 25px;position: absolute;width: 530px;right: 160px;top: 220px">
          江苏某高校，年维持专利数量在5000件左右，学校科技处之前专门安排一个人员每天打电话给各个老师，确定是否需要缴纳年费，然后线下委托服务机构以100/件的价格委托代缴费，并做好纸质文档记录，费时费力，还经常因为遗忘造成产生滞纳金。使用中高托管系统后，全年再没有出现滞纳金情况，工作量、工作时间至少也降低了90%，直接缴费成本更是缩减了70-80%。
        </div>
      </div>

    </div>
  </div> -->
  <div class="content">
    <!-- 头部 -->
    <div class="head">
      <div class="head_content">
        <div class="head_tit1">知识产权托管</div>
        <div class="head_tit1">企业知产财富贴身管家</div>
        <div class="head_tit2">赋能专利人才合理配置，助力企业专利管理省钱、省心、省力</div>
        <div class="check_btn mt30" @click="goEnterprise">  
          <div>进一步了解</div>
          <img src="@/assets/image/index/arrow_r.png" class="arrow_r">
        </div>
        <div class="head_tit2">或致电：400-990-9959</div>
      </div>
    </div>
    <div class="point">
      <div class="point_box">
        <div class="point_item">
          <img src="@/assets/image/index/icon_xl.png" class="point_icon">
          <div class="point_desc">
            <div class="point_fs1">提升效率</div>
            <div class="point_fs2">线上续费，不用等待，省时省力 </div>
          </div>
        </div>
        <div class="point_item">
          <img src="@/assets/image/index/icon_cb.png" class="point_icon">
          <div class="point_desc">
            <div class="point_fs1">节省成本</div>
            <div class="point_fs2">线上一键代缴，成本降低八成 </div>
          </div>
        </div>
        <div class="point_item">
          <img src="@/assets/image/index/icon_fs.png" class="point_icon">
          <div class="point_desc">
            <div class="point_fs1">降低风险</div>
            <div class="point_fs2">专利一键托管，告别滞纳金、未交费失效 </div>
          </div>
        </div>
      </div>
    </div>
    <div class="custody">
      <div class="custody_tit">专利托管介绍</div>
      <div class="custody_fs1">为您提供专利一键托管服务，高效、快捷、安全、放心</div>
      <div class="custody_desc_box">
        <img src="@/assets/image/index/custody_img_new.png" class="custody_img">
        <div class="custody_right">
          <div class="custody_desc">
            <div class="custody_desc_up">
              <div class="border_l"></div>
              <img src="@/assets/image/index/icon_tj.png" class="icon_tj">
              <div class="down_fs">
                <div class="up_fs1">专利托管</div>
                <div class="up_fs2">专利托管系统可为企业、高校、政府、园区、专家、机构提供 </div>
                <div class="up_fs2">①检索查询—②专利监控—③预警通知—④在线缴费—⑤知产服务</div>
                <div class="up_fs2">为一体的信息化工具与载体，助力创新主体的知识产权管理提升效率，</div>
                <div class="up_fs2">节省成本、降低风险、了解竞争对手、跟踪技术趋势。</div>
              </div>
            </div>
            <div class="custody_desc_down">
              <div class="down_fs">
                <div>专业服务团队</div>
                <div>无形资产交易、布局企业生态全周期的一站式服务平台</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="custody_core">
      <div class="custody_tit">专利托管核心功能</div>
      <div class="custody_fs1">告别繁琐操作，大幅提高专利业务办理效率</div>
      <div class="custody_circle">
        <div class="circle_jx_new">
          <div class="circle_box">
            <div class="core_item_r core_p1">
              <img src="@/assets/image/index/core_ztjk.png">
              <div style="margin-left: 20px">
                <div style="text-align: left"><span class="core_fs1">状态监控</span></div>
                <div style="text-align: left">
                  <span class="core_fs2">专利审查与维持过程中60多个法律状态的实时监控，随时了解业务进程</span>
                </div>
              </div>
            </div>
            <div class="core_item_l core_p2">
              <img src="@/assets/image/index/core_zcfw.png">
              <div style="margin-right: 20px">
                <div style="text-align: right"><span class="core_fs1">知产服务</span></div>
                <div style="text-align: right">
                  <span class="core_fs2">提供专利、商标、版权、法律、资质、评估分析等一站式知识产权延伸服务</span>
                </div>
              </div>
            </div>
            <div class="core_item_l core_p3">
              <img src="@/assets/image/index/core_tz.png">
              <div style="margin-right: 20px">
                <div style="text-align: right"><span class="core_fs1">预警通知</span></div>
                <div style="text-align: right">
                  <span class="core_fs2">可选择微信、短信、邮件主动通知费用、状态信息，避免传统模式的遗忘</span>
                </div>
              </div>
            </div>
            <div class="core_item_r core_p4">
              <img src="@/assets/image/index/core_nfjk.png">
              <div style="margin-left: 20px">
                <div style="text-align: left"><span class="core_fs1">年费监控</span></div>
                <div style="text-align: left">
                  <span class="core_fs2">自动同步国知局专利年费信息，清晰显示费用明细，维持成本明明白白</span>
                </div>
              </div>
            </div>
            <div class="core_item_l core_p5">
              <img src="@/assets/image/index/core_zxjf.png">
              <div style="margin-right: 20px">
                <div style="text-align: right"><span class="core_fs1">在线缴费</span></div>
                <div style="text-align: right">
                  <span class="core_fs2">在线支付，提供官方票据【自2021年1月1日起专利缴费提供国知局电子票据</span>
                </div>
              </div>
            </div>
            <div class="core_item_r core_p6">
              <img src="@/assets/image/index/core_zljs.png">
              <div style="margin-left: 20px">
                <div style="text-align: left"><span class="core_fs1">专利检索</span></div>
                <div style="text-align: left">
                  <span class="core_fs2">支持全国2400万全量专利数据的检索、浏览、以及PDF文档的下载等</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="custody_case">
      <div class="custody_tit">成功案例</div>
      <div class="custody_fs1">专利托管为您提供案例、稳定、高效的专利服务及保障</div>
      <div class="case_box">
        <div class="case_l">
          <div class="case_desc">江苏某高校，年维持专利数量在5000件左右，
            学校科技处之前专门安排一个人员每天打电话给各个老师，确定是否需要缴纳年费，
            然后线下委托服务机构以100/件的价格委托代缴费，
            并做好纸质文档记录，费时费力，还经常因为遗忘造成产生滞纳金。使用中高托管系统后，
            全年再没有出现滞纳金情况，工作量、工作时间至少也降低了90%，直接缴费成本更是缩减了70-80%。
          </div>
          <div class="check_btn mt12" @click="goEnterprise">  
            <div>进一步了解</div>
            <img src="@/assets/image/index/arrow_r.png" class="arrow_r">
          </div>
          <div class="case_tel">或致电：400-990-9959</div>
        </div>
        <img src="@/assets/image/index/case_img.png">
      </div>
    </div>
  </div>
</template>

<script>
  import cgcircle from '@/assets/image/index/cgcircle.png'
  import circle_jx from '@/assets/image/index/circle_jx.png'
  import zljs from '@/assets/image/index/zljs.png'
  import zcfw from '@/assets/image/index/zcfw.png'
  import yjtz from '@/assets/image/index/yjtz.png'
  import zxjf from '@/assets/image/index/zxjf.png'
  import ztjk from '@/assets/image/index/ztjk.png'
  import nfjk from '@/assets/image/index/nfjk.png'
  import hj from '@/assets/image/index/hj.png'
  import lq from '@/assets/image/index/lq.png'
  import qb from '@/assets/image/index/qb.png'

  import { checkLogin } from "../../plugins/api/loginApi";
  export default {
    name: "PatentTrusteeshipIndex",
    data() {
      return {
        circle_jx: circle_jx,
        cgcircle: cgcircle,
        zljs: zljs,
        zcfw: zcfw,
        yjtz: yjtz,
        zxjf: zxjf,
        ztjk: ztjk,
        nfjk: nfjk,
        hj,
        lq,
        qb

      }
    },
    methods: {
      goEnterprise() {
        if (!checkLogin(this.$router)) {
          this.logOut();
          return;
        }
        this.$router.push({name: "enterprise", params:{pgId: "patentTrusteeshipCom", openSub: "patentService"}});
      },
    }
  }
</script>

<style scoped lang="scss">


  .center {
    width: 1200px;
    margin: 0 auto;
  }

  .big-zindex {

    background-image: url("~@/assets/image/index/bigzindex.png");

  }

  .small-zindex {
    background-image: url("~@/assets/image/index/smallzindex.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
  }

  .p {
    background-image: url("~@/assets/image/index/p.png");
    background-position: center;
  }

  .div_center_com {
    width: 100%;
  }

  .cpjs {
    height: 575px;
    background-image: url("~@/assets/image/index/cbjs.png");
    background-position: center;
  }

  .big-circle, .small-circle {
    display: inline-block;
    border-radius: 50%;
  }

  .big-circle {
    width: 25px;
    height: 25px;
    background-color: #feae1e;
    margin-right: 5px;
  }

  .small-circle {
    width: 15px;
    height: 15px;
    background-color: #6a66ff;
    margin-left: 5px
  }

  .circle_jx {
    height: 800px;
    background: url("~@/assets/image/index/circle_jx.png") no-repeat;
    background-position: center;
  }

  /*new*/
  .content{
    width: 100%;
    min-width: 1440px;
  }
  .head{
    width: 100%;
    height: 500px;
    background-image: url("~@/assets/image/index/smallzindex_new.png");
    /*background-size: 1920px 500px;*/
  }
  .head_content{
    padding-top: 155px;
    margin-left: 398px;
  }

  .head_tit1{
    /*height: 100px;*/
    font-size: 36px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 50px;
  }
  .head_tit2{
    margin-top: 12px;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 21px;
  }
  .check_btn{
    width: 136px;
    height: 46px;
    background: #1776FF;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
    /*line-height: 21px;*/
  }
  .arrow_r{
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }

  .point{
    width: 100%;
    height: 115px;
    background: #F6F9FD;
  }
  .point_box{
    width: 100%;
    display: flex;
    justify-content: center;

  }
  .point_item{
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 115px;
  }
  .point_icon{
    width: 40px;
    height: 40px;
  }
  .point_desc{
    margin-left: 24px;
  }

  .point_fs1{
    font-size: 16px;
    font-weight: 600;
    color: #484F64;
  }

  .point_fs2{
    font-size: 12px;
    font-weight: 400;
    color: #848CA1;
  }

  .custody{
    width: 100%;
    /*height: 632px;*/
    padding-top: 50px;
    padding-bottom: 100px;
    /*margin-top: 50px;*/
    /*background: #FFFFFF;*/
  }

  .custody_tit{
    text-align: center;
    height: 32px;
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    line-height: 32px;
  }
  .custody_fs1{
    text-align: center;
    margin-top: 24px;
    height: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
  }

  .custody_desc_box{
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  .custody_img{
    width: 360px;
    height: 360px;
  }
  .custody_right{
    display: flex;
    align-items: center;
  }
  .custody_desc{
    margin-left: 60px;
    width: 760px;
  }
  .custody_desc_up{
    position: relative;
    width: 760px;
    height: 208px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
  }
  .up_fs1{
    font-size: 18px;
    font-weight: 600;
    color: #484F64;
    line-height: 30px;
  }
  .up_fs2{
    font-size: 16px;
    font-weight: 400;
    color: #484F64;
    line-height: 26px;
  }
  .border_l{
    width: 2px;
    height: 208px;
    background: #1890FF;
    position: absolute;
    left: 0;
  }
  .icon_tj{
    position: absolute;
    right: 30px;
    top: -6px;
  }
  .custody_desc_down{
    width: 760px;
    height: 110px;
    background: #F6F9FD;
    border-radius: 0px 0px 4px 4px;
    display: flex;
    align-items: center;
  }

  .down_fs{
    margin-left: 35px;
    font-size: 16px;
    font-weight: 400;
    color: #484F64;
    line-height: 26px;
  }

  .custody_core{
    width: 100%;
    height: 863px;
    padding-top: 50px;
    background: #F6F9FD;
    border-radius: 4px;
  }
  .custody_circle{
    margin-top: 40px;
    text-align: center;
  }

  .circle_jx_new{
    height: 634px; 
    background: url("~@/assets/image/index/circle_jx_new.png") no-repeat;
    background-position: center;
  }

  .circle_box{
    margin: 0px auto;
    height: 634px;
    width: 634px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .core_item_l{
    height: 100px;
    width: 380px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    position: absolute;
  }
  .core_item_r{
    height: 100px;
    width: 380px;
    display: flex;
    align-items: center;
    position: absolute;
  }
  .core_fs1{
    font-size: 18px;
    font-weight: 600;
    color: #333333;
  }
  .core_fs2{
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
  .core_p1{
    left: 440px;
    bottom: 52px;
  }

  .core_p2{
    right: 506px;
    bottom: 100px;
  }
  
  .core_p3{
    right: 450px;
    top: 42px;
  }
  .core_p4{
    left: 550px;
    bottom: 220px;
  }
  .core_p5{
    right: 560px;
    top: 250px;
  }
  .core_p6{
    left: 506px;
    top: 92px;
  }
  .custody_case{
    width: 100%;
    height: 632px;
    padding-top: 50px;
  }
  .case_box{
    /*margin: 0 auto;*/
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
  .case_l{
    padding-left: 40px;
    width: 660px;
    height: 360px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px 0px 0px 4px
  }
  .case_desc{
    padding: 40px 40px 0px 0px;
    font-size: 18px;
    font-weight: 400;
    color: #484F64;
    line-height: 30px;
  }
  .case_tel{
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 17px;
    margin-top: 12px;
  }
  .mt12{
    margin-top: 24px;
  }
  .mt30{
    margin-top: 60px;
  }
</style>
